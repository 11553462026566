
$color-black : #222222;
$color-white : #fff;
$color-themea : #007958;
$color-themeb : #104277;

$font-regular : 1rem;
$font-mubhead : 20px;
$font-subhead : 25px;
$font-mhead : 30px;
$font-head : 36px;





.font-regular {
    font-size: 15px; }
.font-msubhead {
    font-size: 18px; }
.font-subhead {
    font-size: 21px; }

.font-mhead {
    font-size: 30px; }

.font-head {
    font-size: 36px; }



.font-regular {
    font-size: 1rem; }
.font-msubhead {
    font-size: 20px; }
.font-subhead {
    font-size: 25px; }

.font-mhead {
    font-size: 30px; }

.font-head {
    font-size: 36px; }


.hover-effect {
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    background-size: 300% 100% !important;

    background-image: linear-gradient(to right, $color-themea, $color-themeb, $color-themea, $color-themeb) !important;
    box-shadow: 0 4px 15px 0 rgba(0, 121, 88, 0.75) !important;


    &:hover {
        background-position: 100% 0 !important;
        moz-transition: all .4s ease-in-out;
        -o-transition: all .4s ease-in-out;
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out; } }





.cursor-pointer {
    cursor: pointer; }



.div-loading-still {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

    align-items: center;

    z-index: 999999;
    background: $color-white;

    >div {
        margin: 0 auto;
        >img {
            width: 150px;
            height: auto; } } }



.cursor-wait {
    cursor: wait; }

//@include media-breakpoint-up(sm) { ... }
//@include media-breakpoint-up(md) { ... }
//@include media-breakpoint-up(lg) { ... }
//@include media-breakpoint-up(xl) { ... }


@import './components/NavBar/index.sass';
@import './components/Home/index.sass';
@import './components/Footer/index.sass';
