
.nav-parent {



    .site-header {
        background-color: rgba(255, 255, 255, 0.85);
        padding: 1rem 0 1rem;

        position: fixed;
        width: 100%;
        background: white;
        z-index: 99;
        border-bottom: 1px solid #f2f3f1;
        top: 0;
        transition: top 0.2s linear 0s;


        a {
            color: #999;
            transition: ease-in-out color .15s; }
        &:hover {
            color: #fff;
            text-decoration: none; }
        .navbar-brand {
            height: 57px;
            img {
                max-height: 100%;
                width: auto; } }
        .container {
            max-width: 1400px; }
        .collapse {}
        .navbar-collapse {
            .navbar-nav {
                >li {
                    padding: 0.3rem;
                    >a {
                        padding: 0.3rem 1.3rem;
                        font-weight: 400;
                        color: $color-black;

                        // -moz-transition: all .2s ease-in;
                        // -o-transition: all .2s ease-in;
                        // -webkit-transition: all .2s ease-in;
                        // transition: all .2s ease-in;

                        &:hover , &.active {
                            color: $color-white;
                            background: linear-gradient(90deg, $color-themeb 0%, $color-themea 100%);
                            border-radius: 25px; } } } } } } }



