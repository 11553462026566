footer {
    background-color: $color-black;
    color: $color-white;
    .container-fluid {
        border-top: 1px solid #3e3e3e;
        &.bttm {
            p {
                margin: 0;
                font-size: 12px;
                font-weight: 300; } } }

    .container {


        .image-logo {
            text-align: center;
            image {
                width: 100%;
                height: auto; } } } }





@include media-breakpoint-down(sm) {
    footer {
        .container {
            .row {
                .col-12 {
                    padding-top: 1rem !important;
                    padding-bottom: 1rem !important; } } } } }
