.punarjeev-home {
    max-height: var(--breakpoint-xl);
    .row {
        >div {
            margin: 1rem 0; } }
    .home-content {
        margin-right: -70px;
        .heada {
            p {
                margin: 0;
                font-size: 35px;
                font-weight: 600;
                color: $color-themea; }
            p:first-child {
                font-size: 20px;
                font-weight: 500;
                color: $color-themeb;
                margin-bottom: 1rem; }
            p:nth-child(3) {
                line-height: 3rem; } }
        .headb {
            margin-top: 2rem;
            padding: 0.2rem 0.4rem;
            border-left: 5px solid $color-themeb;
            >p {
                margin: 0; } }

        .headc {
            margin-top: 2rem;

            >a {
                padding: 0.5rem 2rem;
                color: $color-white;
                background: linear-gradient(90deg, $color-themeb 0%, $color-themea 100%);
                border-radius: 25px; } } }
    .home-image {
        >img {
            margin-top: -1px;
            max-width: 100%;
            height: auto;
            width: auto; } } }








.punarjeev-home01 {

    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% 0px;

    .shadebox-parent {}
    .shadebox {
        min-height: 1rem;
        padding: 0.5rem;

        .card {
            border: 0;
            min-height: 280px;

            color: $color-white;
            background: linear-gradient(0deg, $color-themeb 0%, $color-themea 100%);
            border-radius: 15px;

            .card-body {
                padding: 1.5rem 0.6rem;
                flex: 1;
                display: flex;
                flex-direction: column; }

            .card-h {

                .avathar {
                    margin: 0 auto;
                    border-radius: 50%;
                    width: 70px;
                    height: 70px;
                    min-width: 70px;
                    background: $color-white;
                    text-align: center;
                    padding: 10px;

                    >img {
                        max-width: 100%;
                        height: 100%; }

                    >i {
                        color: $color-themeb;
                        font-size: 3rem; } } }

            .card-b {
                margin: auto 0;
                text-align: center;
                >p {
                    paddding: 0.5rem;
                    margin: 0; } } } }



    .home2-vision {
        padding: 2rem;
        >h4 {
            color: $color-themea; }

        >p {
            color: $color-black;
            line-height: 1.6rem; } } }





.punarjeev-products {


    background-repeat: no-repeat;
    background-position: right 9rem;
    background-size: contain;

    background-color: #f2f3f1;


    .headc {
        margin-top: 2rem;

        >a {
            padding: 0.5rem 2rem;
            color: $color-white;
            background: linear-gradient(90deg, $color-themeb 0%, $color-themea 100%);
            border-radius: 25px; } }

    .each-product-base {
        margin-top: 6rem;


        .product-background {
            position: absolute;
            right: 0;
            left: auto;
            width: 54%;
            bottom: 10px;
            &.invert {
                right: unset;
                left: 0;
                filter: FlipH;
                -moz-transform: scaleX(-1);
                -o-transform: scaleX(-1);
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
                filter: FlipH;
                -ms-filter: "FlipH"; } }

        .product-props {
            list-style: none;
            padding: 0;
            margin-top: 1rem;
            li {
                margin-bottom: 0.5rem;

                >span:first-child {
                    padding-right: 1rem; }
                .list-circle {
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    margin-top: 5px;
                    background: radial-gradient(circle, $color-themeb 0%, $color-themea 100%); } } }




        .container {
            min-height: 40rem; } }

    .container {
        display: flex;
        flex-direction: column; }



    .font-head {
        color: $color-themeb;
        >span {
            color: $color-themea; } }


    .content {
        .font-subhead {
            color: $color-themea; }

        .font-msubhead {
            color: $color-themeb; }

        .font-regular {
            color: $color-black; } }



    .proudct-image {
        >img {
            margin-left: auto;
            max-width: 90%;
            border-radius: 10px;
            z-index: 1; } }






    .invert {
        .proudct-image {
            >img {
                margin: 0;
                margin-right: auto; } } } }


@include media-breakpoint-up(sm) {
    .punarjeev-products {} }


@include media-breakpoint-down(sm) {
    .punarjeev-products {
        .proudct-image {
            >img {
                max-width: 100%; } }

        .each-product-base {
            margin-bottom: 2rem;
            .product-background {
                bottom: -3%;
                width: 100%; } } }

    .punarjeev-products {
        .each-product-base {
            &.invert {
                .product-background {
                    bottom: unset;
                    top: -5%; } } } } }









.punarjeev-contact-us {

    background-repeat: no-repeat;
    background-size: cover;
    background-color: #f2f3f1;

    background-color: #f2f3f1;
    color: $color-white;
    padding: 8rem 0 5rem !important;

    .container {
        display: flex;
        flex-direction: column;

        &:first-child {
            margin-top: 4rem; } }



    .font-head {
        color: $color-white;
        >span {
            color: $color-themea; } }


    .contact-us-body {
        margin-top: 3rem;
        a {
            padding: 0.5rem 2rem;
            color: #fff;
            background: linear-gradient(90deg, #104277 0%, #007958 100%);
            border-radius: 25px;
            border: 1px solid;
            >span {
                &:first-child {
                    margin-right: 10px;
                    i {
                        width: 40px;
                        height: 40px; } } } } } }




@include media-breakpoint-down(sm) {
    .punarjeev-contact-us {
        background-position: top;
        paading-top: 1rem;
        margin-top: -1rem !important; }
    .contact-us-body {
        .row {
            .col-sm-12 {
                text-align: center !important; } } }

    .product-background {
        display: none; }

    .container {
        >.row {
            >.col-md-7 {
                order: 2; } } } }

















.punarjeev-testimonials {
    background: $color-white;
    color: $color-black;
    padding: 5rem 0 3rem   !important;

    .font-head {
        color: $color-themeb;
        >span {
            color: $color-themea; } }


    .testimonials-body {
        .card {
            border: 2px solid $color-themea;
            border-radius: 25px;

            .card-body {
                .content {
                    text-align: center;
                    margin-bottom: 0;

                    >i {
                        &:first-child {
                            margin-right: 10px;
                            color: $color-themea; }

                        &:last-child {
                            margin-left: 10px;
                            color: $color-themeb; } }
                    .text-link {
                        color: $color-themea;
                        cursor: pointer; } } }
            .card-footer {
                &.user {
                    background: transparent;
                    border: 0;
                    text-align: left;
                    >div {
                        max-width: 80%; }
                    >img {
                        width: 70px;
                        height: 70px;
                        min-height: 70px;
                        margin: auto 0;
                        margin-right: 10px;
                        border-radius: 50%;
                        min-width: 70px !important; }
                    .name {
                            color: $color-themeb;
                            font-weight: 500;
                            margin-right: 1rem; }
                    span {

                        &:last-child {
                            margin-top: auto;
                            color: $color-themea; } } } } } }



    .slick-slider {
        &.slick-initialized {
            .slick-arrow {
                font-family: IcoFont !important;
                speak: none;
                font-style: normal;
                font-weight: 600;
                font-variant: normal;
                text-transform: none;
                white-space: nowrap;
                word-wrap: normal;
                direction: ltr;
                line-height: 1;
                -webkit-font-feature-settings: "liga";
                -webkit-font-smoothing: antialiased;
                font-size: 2rem;
                margin-top: -1.5rem;
                &.slick-prev {
                    &:before {
                        font-size: 2rem;
                        color: $color-themea;
                        font-family: IcoFont !important;
                        content: "\ea78"; } }

                &.slick-next {
                    &:before {
                        font-size: 2rem;
                        color: $color-themea;
                        font-family: IcoFont !important;
                        content: "\ea79"; } } } } }
    .card-footer {
        .position-absolute {
            padding: 0 1.25rem;
            bottom: 0.6rem; } } }


.punarjeev-products {

    .move-cursor {
        .slick-slide {
            cursor: move !important; } }

    .slick-slider {
        &.slick-initialized {

            .slick-slide {
                padding: 0 10px;
                text-align: center;
                img {
                    border-radius: 25px; } }



            .slick-dots {
                display: flex !important;
                width: 50%;
                left: 25%;
                bottom: -30px;
                justify-content: center;

                >li {

                    float: left;
                    flex-grow: 0;
                    -webkit-transition: width 1s;
                    transition: width 1s;

                    button {

                        -webkit-transition: width 1s;
                        transition: width 1s;

                        &:before {
                            width: 10px;
                            -webkit-transition: width 1s;
                            transition: width 1s; } }


                    button {
                        &:before {
                            width: 100%;
                            height: 10px;
                            content: ' ';
                            border-radius: 50px;
                            background: $color-white; } } }
                >li {
                    &.slick-active {
                        flex-grow: 1; } } } } } }







.punarjeev-success {

    .slick-slider {
        &.slick-initialized {} }

    .success_stories {
        .cust-view {
            border-radius: 50px;
            background: $color-white;
            color: $color-black !important;
            font-size: 12px; } }
    .font-head {
        color: $color-themeb;
        >span {
            color: $color-themea; } }


    .success__peach {
        position: relative;
        overflow: hidden; }

    .success__image {
        display: block;
        max-width: 100%; }

    .success__peach:before {
        content: ""; }

    .success_stories {
        .success__peach {
            &:before {
                width: 0;
                height: 0;
                padding: 25%;
                background-color: var(--successOverlay, rgba(0, 0, 0, 0.81));
                border-radius: 50%;
                position: absolute;
                top: 50%;
                left: 50%;
                transition: transform calc(var(--successAnimationDuration, .8s) / 2) ease;
                will-change: transform;
                transform: translate(-50%, -50%) scale(0); } }
        &:hover {
            .success__peach {
                &:before {
                    transform: translate(-50%, -50%) scale(4);
                    transition-duration: var(--successAnimationDuration, 0.8s); } } }
        .success__label {
            width: 95%;
            text-align: center;
            transform: translate(-200%, -50%);
            transition: transform calc(var(--successAnimationDuration, .8s) / 2) ease-out;
            will-change: transform;
            position: absolute;
            top: 50%;
            left: 50%; }

        &:hover {
            .success__label {
                transition-duration: calc(var(--successAnimationDuration, .8s) / 4);
                transform: translate(-50%, -50%); } } } }





.punarjeev-youtube {
    .modal {
        background: rgba(0,0,0, 0.81);

        .videowrapper {
            float: none;
            clear: both;
            width: 100%;
            position: relative;
            padding-bottom: 56.25%;
            padding-top: 25px;
            height: 0;

            background-repeat: no-repeat;
            background-position: center;
            background-size: 63px; }

        .videowrapper {
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%; } } }


    .modal-body {
        overflow: hidden;

        position: relative;

        .show-content {
            &.show {
                top: 0; }
            position: absolute;
            top: 0;
            left: 0;
            top: 100%;
            bottom: 0;

            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.9);
            -webkit-transition: top 1s;
            transition: top 1s;
            z-index: 3;
            overflow: auto;
            color: white;
            padding: 0.4rem;
            p {
                color: white; } } } }
